import Sortable from 'sortablejs';

const initSortablePart = () => {
    var el = document.getElementById('parties-list');
    var sortable = new Sortable(el, {
        onEnd: function (evt) {
            var id = evt.item.getAttribute('data-id');
            var newIndex = evt.newIndex + 1; // Les index de acts_as_list commencent à 1
            fetch(`parties/${id}/update_position`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({ partie: { position: newIndex } })
            });
        }
    });
}

export { initSortablePart };
