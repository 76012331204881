const initModal = () => {
    const modal = document.getElementById('modal');
    const showModalBtns = document.querySelectorAll('.show-modal');
    const closeModalBtn = document.querySelector('.close');
    const emailForm = document.getElementById('email-form');
    let currentDocumentId = null;

    if (!modal) return;

    if (showModalBtns.length > 0) {
        showModalBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                currentDocumentId = btn.getAttribute('data-document-id');
                modal.style.display = 'flex';
            });
        });
    }

    if (closeModalBtn) {
        closeModalBtn.addEventListener('click', () => {
            modal.style.display = 'none';
        });
    }

    if (emailForm) {
        emailForm.addEventListener('submit', (e) => {
            e.preventDefault();
            const email = document.getElementById('email').value;

            fetch('/documents/send_document', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({ email: email, document_id: currentDocumentId })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success && currentDocumentId) {
                        const documentElement = document.getElementById(`document-${currentDocumentId}`);
                        const buttonElement = document.querySelector(`.show-modal[data-document-id="${currentDocumentId}"]`);
                        const overlayElement = document.getElementById(`overlay-${currentDocumentId}`);
                        const overlayContainer = buttonElement ? buttonElement.closest('.document-overlay') : null;

                        if (documentElement) documentElement.classList.remove('flouté');
                        if (buttonElement) buttonElement.style.display = 'none';
                        if (overlayElement) overlayElement.style.display = 'none';
                        if (overlayContainer) overlayContainer.style.display = 'none';

                        modal.style.display = 'none';
                    }
                });
        });
    }

    window.addEventListener('click', (e) => {
        if (e.target === modal) {
            modal.style.display = 'none';
        }
    });
};

export { initModal };
