const initCookies = () => {
    setTimeout(function() {
        var cookieConsentContainer = document.getElementById('cookieConsentContainer');
        if (cookieConsentContainer) {
            cookieConsentContainer.classList.add('show');
        }
    }, 5000);

    var acceptCookiesBtn = document.getElementById('acceptCookies');
    if (acceptCookiesBtn) {
        acceptCookiesBtn.addEventListener('click', function() {
            var cookieConsentContainer = document.getElementById('cookieConsentContainer');
            if (cookieConsentContainer) {
                cookieConsentContainer.style.opacity = '0';
                setTimeout(function() {
                    cookieConsentContainer.style.display = 'none';
                }, 500);
            }

            document.cookie = "accept_cookies=true; path=/; max-age=" + 60*60*24*365;
            loadGATag();
        });
    }

};
function loadGATag() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7Y3B5Q6M71';
    document.head.appendChild(script);

    script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-7Y3B5Q6M71');
    };
}

export { initCookies };
