const initLiveEditor = () => {
    let live_editor = document.getElementsByName('live_editor_container');
    if (live_editor.length > 0) {
        let live_elements = document.querySelectorAll('[name^="live_editor_content"]');
        // Données textuelles
        let texte_inputs = document.querySelectorAll('input[type^="text"]');
        for(let texte_input of texte_inputs) {
            texte_input.addEventListener('keyup', (event) => {
                for(let live_element of live_elements) {
                    let element_names = live_element.getAttribute('name').split(' ')[1].split('-');
                    if (element_names[0] == "style") {
                        let ele_input = document.querySelector('[name^="article[' + element_names[1] + ']"]');
                        if(ele_input !== null) {
                            live_element.style.backgroundColor = ele_input.value;
                        }
                    } else if (element_names[0] == "content") {
                        let ele_input = document.querySelector('[name^="article[' + element_names[1] + ']"]');
                        if(ele_input.value !== null) {
                            live_element.innerHTML = ele_input.value;
                        }
                    }
                }
            });
        }
        // Données images
        let file_inputs = document.querySelectorAll('[type^="file"]');
        for(let file_input of file_inputs) {
            file_input.addEventListener('change', (event) => {
                let live_element_div = document.querySelector('[name^="live_editor_content ' + 'img-' + file_input.getAttribute('name').replace(']', '').replace('article[', '') + '"]');
                live_element_div.src = URL.createObjectURL(event.target.files[0]);

            });

        }
    }
};

export { initLiveEditor };