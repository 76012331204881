const initINFCSlider = () => {
    window.addEventListener('load', (event) => {
        let sliders = document.getElementsByClassName('infc_slider');
        if('Start' == 'Start') {
            for(let slider of sliders) {
                // Cloning track
                let slider_width = slider.offsetWidth;
                let slide_track = slider.children[0];
                let slide_track_width = slide_track.offsetWidth;
                let slide_track_clone = slider.children[0].cloneNode(true);
                slide_track_clone.classList.add('slide_track_clone');
                slide_track.after(slide_track_clone);

                // Launching animation
                let id = null;
                let pos = 0;
                let multiplier = 1;
                clearInterval(id);
                id = setInterval(animate, 20);
                

                // Animation
                function animate() {
                    // detect when a track leaves the slider
                    if(slide_track.classList.contains('left')) { multiplier = -1 }
                    if(pos == slide_track_width + 20) {
                        pos = 0;
                    } else {
                        pos++;
                    }
                    // move tracks
                    slide_track.style.transform = "translateX(" + (multiplier * pos) + "px)";
                    slide_track_clone.style.transform = "translateX(" + (multiplier * pos) + "px)";
                }
            }
        }
    });
}

export { initINFCSlider };